<script setup>
 const props = defineProps({
  url: {
   type: [String, Boolean],
   default: '',
  },
  target: {
   type: [String, Boolean],
   required: false,
  },
  tag: {
   type: String,
   default: '',
  },
  image: {
   type: [String, Object],
   required: false,
  },
  title: {
   type: String,
   default: '',
  },
  dates: {
   type: Array,
   default: [],
   required: false,
  },
  body: {
   type: String,
   required: false,
  },
  height: {
   type: [Number, Boolean],
   required: false,
   default: false,
  },
  width: {
   type: [Number, Boolean],
   required: false,
   default: false,
  },
 });

 const { url, target, tag, image, title, dates, body } = toRefs(props);

 const formattedDates = computed(() => {
  let string = '';
  if (!dates?.value?.length) return string;

  switch (dates?.value?.length) {
   case 1:
    string = dates?.value[0];
    break;
   case 2:
   case 3:
    string = dates?.value.join(' & ');
    break;
   default:
    string = `${dates?.value[0]} - ${dates?.value[dates?.value.length - 1]}`;
  }

  return string;
  return dates?.value?.join(' & ');
 });
</script>

<template>
 <MessLink v-if="url" :href="url" :target="target" class="card-link">
  <figure class="image-wrapper">
   <MessImage
    v-if="image?.src || image?.length"
    :src="image?.src || image"
    :alt="image?.alt"
    :height="height"
    :width="width"
   />
   <div v-else class="mess-image place-holder" :height="height" :width="width"></div>
   <ProjectPill v-if="tag" :text="tag" class="image-tag">
    <slot name="pillInnerText"></slot>
   </ProjectPill>
  </figure>

  <div class="content-wrapper">
   <MessHtml v-if="title" :html="title" class="card-title h4" tag="h3" />
   <MessHtml v-if="body" :html="body" tag="article" />
   <MessHtml v-if="dates?.length" class="date text-red h5" :html="formattedDates" tag="h4" />
   <slot></slot>
  </div>
 </MessLink>
 <div v-else class="card-link">
  <figure class="image-wrapper">
   <MessImage v-if="image" :image="image" :height="height" :width="width" />
   <ProjectPill v-if="tag" :text="tag" class="image-tag">
    <slot name="pillInnerText"></slot>
   </ProjectPill>
  </figure>

  <div class="content-wrapper">
   <MessHtml v-if="title" :html="title" class="card-title h4" tag="h3" />
   <MessHtml v-if="body" :html="body" tag="article" />
   <MessHtml v-if="dates?.length" class="date text-red h5" :html="formattedDates" tag="h4" />
   <slot></slot>
  </div>
 </div>
</template>

<style lang="scss">
 .card-link {
  overflow: hidden;
  // transition: 1500ms ease;
  --transition: 300ms ease-in-out;

  .image-wrapper {
   position: relative;

   .pill {
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
   }
  }

  .mess-image {
   height: 100%;
   position: relative;
   z-index: 0;
   overflow: hidden;

   &.place-holder {
    background-color: var(--gray-100);
   }

   &::after {
    @include absolute-center;

    content: '';
    background-color: var(--red);
    opacity: 0;
    z-index: 2;
    mix-blend-mode: screen;
    transition: var(--transition);
    transition-delay: 200ms;
   }

   img {
    transition: var(--transition);
    filter: grayscale(0%);
   }
  }

  .content-wrapper {
   background: var(--white);
   position: relative;
   z-index: 1;
   padding: 25px 0;
   transition: var(--transition);

   .card-title {
    margin-bottom: 30px;
   }

   article {
    font-size: 16px;
   }
  }
 }
 a.card-link {
  &:hover {
   .content-wrapper {
    transform: translate(0, -6rem);
   }
   .mess-image {
    img {
     filter: grayscale(100%);
     scale: 1.025;
    }
   }
  }
 }
</style>
